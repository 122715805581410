import axios from 'axios';
import global from "../common";
axios.defaults.withCredentials = true
const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: 'https://cdn.moheweb.com/ksapi/',
    withCredentials: true,
    crossDomain: true,
    timeout: 120000,
    Headers: {
        'Content-Type': 'application/json'
    },
});

service.interceptors.request.use(
    config => {
        // console.log(config);
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {

            function isJson(str) {
                if (typeof str == 'string') {
                    return false;
                } else {
                    return true;
                }
            }

            if (!isJson(response.data)) {
                try {
                    // console.log("response.data:", );
                    response.data=JSON.parse(response.data.replace('\uFEFF', ''));
                } catch (e) {
                    console.log("error", e);
                }
            }

            try {
                if (response.data.results.message > 10001) {
                    alert('你的权限不足，请重新登录!')
                    global.clearLoginInfo();
                    global.clearTdata();
                    window.location.href = "#/login";
                } else if (response.data.results.message == 10001) {
                    // alert('过长时间没有操作，请重新登录!')
                    global.clearLoginInfo();
                    global.clearTdata();
                    window.location.href = "#/login";
                }
            } catch (e) {
                console.log("response.data.results.message", e);
            }
            return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

export default service;