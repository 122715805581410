// 定义一些公共的属性和方法
// const baseUrl = 'https://cdn.moheweb.com/ks/api/';
// const baseUrl = '/vdb/gweb/api2/';
// const baseUrl = '/vdb/gweb/api/';
const baseUrl = '/api/';

var loginB = localStorage.getItem('ks_login') !== null ? localStorage.getItem('ks_login') : false;
function commonFun() {
  console.log("公共方法")
}
function setlocalStorage(key, value) {
  localStorage.setItem(key, value);
}
function getlocalStorage(key) {
  return localStorage.getItem(key) !== null ? localStorage.getItem(key) : '';
}
function setPageKey(key) {
  localStorage.setItem('ks_pageKey', key);
}
function getPageKey() {
  let ks_pageKey = localStorage.getItem('ks_pageKey') !== null ? localStorage.getItem('ks_pageKey') : 'index'
  return ks_pageKey;
}
function setLoginInfo(ft, userName, realName, uid, level, keys, rules, city, citycode, dis, discode, unid, unname, photourl) {
  localStorage.setItem('ksa_login', ft);
  localStorage.setItem('ksa_userName', userName);
  localStorage.setItem('ksa_realName', realName);
  localStorage.setItem('ksa_uid', uid);
  localStorage.setItem('ksa_level', level);
  localStorage.setItem('ksa_authkeys', keys);
  localStorage.setItem('ksa_authrules', rules);
  localStorage.setItem('ksa_city', city);
  localStorage.setItem('ksa_citycode', citycode);
  localStorage.setItem('ksa_dis', dis);
  localStorage.setItem('ksa_discode', discode);
  localStorage.setItem('ksa_unid', unid);
  localStorage.setItem('ksa_unname', unname);
  localStorage.setItem('ksa_photourl', photourl);
}
function clearLoginInfo() {
  localStorage.removeItem("ksa_login");
  localStorage.removeItem("ksa_userName");
  localStorage.removeItem("ksa_realName");
  localStorage.removeItem("ksa_uid");
  localStorage.removeItem("ksa_level");
  localStorage.removeItem('ksa_authkeys');
  localStorage.removeItem('ksa_authrules');
  localStorage.removeItem('ksa_city');
  localStorage.removeItem('ksa_citycode');
  localStorage.removeItem('ksa_dis');
  localStorage.removeItem('ksa_discode');
  localStorage.removeItem('ksa_unid');
  localStorage.removeItem('ksa_unname');
  localStorage.removeItem('ksa_photourl');
}
function clearTdata() {
  localStorage.removeItem("ks_tdata");
  localStorage.removeItem("ks_finish");
}

function setTdataInfo(tdata) {
  let ks_finish = localStorage.getItem('ks_finish') !== null ? localStorage.getItem('ks_finish') : 'false';
  if (ks_finish == 'false') {
    localStorage.setItem('ks_tdata', JSON.stringify(tdata));
  }

}
function setFinish() {
  // console.log(JSON.stringify(tdata));
  localStorage.setItem('ks_finish', 'true');
}



function getLoginA() {
  return {
    ksa_login: localStorage.getItem('ksa_login') !== null ? localStorage.getItem('ksa_login') : 'false',
    ksa_userName: localStorage.getItem('ksa_userName') !== null ? localStorage.getItem('ksa_userName') : '',
    ksa_realName: localStorage.getItem('ksa_realName') !== null ? localStorage.getItem('ksa_realName') : '',
    ksa_uid: localStorage.getItem('ksa_uid') !== null ? localStorage.getItem('ksa_uid') : '',
    ksa_level: localStorage.getItem('ksa_level') !== null ? localStorage.getItem('ksa_level') : '',
    ksa_authkeys: localStorage.getItem('ksa_authkeys') !== null ? localStorage.getItem('ksa_authkeys') : '',
    ksa_authrules: localStorage.getItem('ksa_authrules') !== null ? localStorage.getItem('ksa_authrules') : '',
    ksa_city: localStorage.getItem('ksa_city') !== null ? localStorage.getItem('ksa_city') : '',
    ksa_citycode: localStorage.getItem('ksa_citycode') !== null ? localStorage.getItem('ksa_citycode') : '',
    ksa_dis: localStorage.getItem('ksa_dis') !== null ? localStorage.getItem('ksa_dis') : '',
    ksa_discode: localStorage.getItem('ksa_discode') !== null ? localStorage.getItem('ksa_discode') : '',
    ksa_unid: localStorage.getItem('ksa_unid') !== null ? localStorage.getItem('ksa_unid') : '',
    ksa_unname: localStorage.getItem('ksa_unname') !== null ? localStorage.getItem('ksa_unname') : '',
    ksa_photourl: localStorage.getItem('ksa_photourl') !== null ? localStorage.getItem('ksa_photourl') : '',
  }
}

function getTdata() {
  let ks_tdata = localStorage.getItem('ks_tdata') !== null ? localStorage.getItem('ks_tdata') : '{}'
  return JSON.parse(ks_tdata);
}

function searchrule(key) {
  let rule = '0';
  if (localStorage.getItem('ksa_authkeys') != null) {
    const authkeyary = localStorage.getItem('ksa_authkeys').split(',');
    const authruleary = localStorage.getItem('ksa_authrules').split(',');
    const level = localStorage.getItem('ksa_level') !== null ? localStorage.getItem('ksa_level') : '';
    authkeyary.map((item, index) => {
      if (item == key) {
        rule = authruleary[index];
      } else if (item != key && item.indexOf(key) > -1 && authruleary[index] > 0) {
        rule = 100;
      }
    })
    if (level == '0') {
      rule = 3;
    }
  }
  return rule;
}

function checkrule() {
  return searchrule(getPageKey());
}


function setIndexDclass(classtext) {
  localStorage.setItem('IndexDclass', classtext);
}
function getIndexDclass() {
  let classtext = localStorage.getItem('IndexDclass') !== null ? localStorage.getItem('IndexDclass') : '';
  return classtext;
}


function getSider() {
  const sider = [
    { title: "基础管理", key: "basemag", rule: searchrule('basemag'), level: [1, 2, 3, 4], icon: "", component: "basemag" },
    { title: "英文基础管理", key: "baseenmag", rule: searchrule('baseenmag'), level: [1, 2, 3, 4], icon: "", component: "baseenmag" },
    { title: "客户伙伴管理", key: "friendmag", rule: searchrule('friendmag'), level: [1, 2, 3, 4], icon: "", component: "friendmag" },
    { title: "合作客户管理", key: "friendhmag", rule: searchrule('friendhmag'), level: [1, 2, 3, 4], icon: "", component: "friendhmag" },
    { title: "案例管理", key: "casemag", rule: searchrule('casemag'), level: [1, 2, 3, 4], icon: "", component: "casemag" },
    { title: "获奖管理", key: "awardsmag", rule: searchrule('awardsmag'), level: [1, 2, 3, 4], icon: "", component: "awardsmag" },
    { title: "分类管理", key: "classmag/kind", rule: searchrule('classmagkind'), level: [1, 2, 3, 4], icon: "", component: "classmagkind" },

    {
      title: "管理员管理", key: "teammag", rule: searchrule('teammag'), level: [1], icon: "", component: "teammag", list: [
        // { title: "单位管理", key: "teammag/unit", rule: searchrule('infomag/unit'), level: [1, 2, 3], icon: "", component: "" },
        // { title: "组织结构", key: "teammag/org", rule: searchrule('infomag/org'), level: [1, 2, 3], icon: "", component: "" },
        { title: "人员管理", key: "teammag/list", rule: searchrule('infomag/list'), level: [1, 2, 3], icon: "", component: "" },
      ]
    },
  ];
  return sider;
}

function gethkindtext(kind) {

  let text = "";
  switch (kind) {
    case "1":
      text = "浑南总干";
      break;
    case "2":
      text = "沈抚灌渠";
      break;
    case "3":
      text = "桃仙河";
      break;
    case "4":
      text = "十里河";
      break;
    case "5":
      text = "北沙河";
      break;
    case "6":
      text = "秀匠排干";
      break;
    case "7":
      text = "白清寨河";
      break;
    case "8":
      text = "杨木河";
      break;
    case "9":
      text = "白清寨河北支";
      break;
    case "10":
      text = "刘千户屯河";
      break;
    case "11":
      text = "奉集堡河";
      break;
    case "12":
      text = "朱庄子河";
      break;
    case "13":
      text = "浑河";
      break;
    case "14":
      text = "八一总干";
      break;
    case "15":
      text = "六一排干";
      break;
    case "16":
      text = "新开河";
      break;
    case "17":
      text = "韭菜河一排干";
      break;
    case "18":
      text = "柳塘沟河";
      break;
  }
  return text;
}


function setIndexscroll(value) {
  localStorage.setItem('IndexScroll', value);
}
function getIndexscroll() {
  let value = localStorage.getItem('IndexScroll') !== null ? localStorage.getItem('IndexScroll') : '0';
  return value;
}
function setPlistscroll(value) {
  localStorage.setItem('PlistScroll', value);
}
function getPlistscroll() {
  let value = localStorage.getItem('PlistScroll') !== null ? localStorage.getItem('PlistScroll') : '0';
  return value;
}


// 暴露出这些属性和方法
export default {
  baseUrl,
  loginB,
  commonFun,
  setLoginInfo,
  clearLoginInfo,
  getLoginA,
  setTdataInfo,
  getTdata,
  clearTdata,
  setFinish,
  setPageKey,
  getPageKey,
  getSider,
  searchrule,
  checkrule,
  setlocalStorage,
  getlocalStorage,
  gethkindtext,
  setIndexDclass,
  getIndexDclass,
  setIndexscroll,
  getIndexscroll,
  setPlistscroll,
  getPlistscroll
}